import request from '@/../utils/request'
const api_name = '/user-authority-info'
export default {
    //
    verification(username, password) {
        return request({
            url: `${api_name}/verification`,
            method: 'post',
            data: {
                name: username,
                password: password,
            }
        })
    },
}