<template>
  <div id="map">
    <baidu-map :zoom="zoom" class="map" :center="{lng:mark.lng, lat: mark.lat}" :scroll-wheel-zoom="true">
      <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" type="BMAP_NAVIGATION_CONTROL_LARGE" :offset="{width: 40,height:60}"></bm-navigation>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" type="BMAP_NAVIGATION_CONTROL_PAN" :offset="{width: 40,height:10}"></bm-navigation>

      <bm-marker :position="{lng:mark.lng, lat: mark.lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>

      <!-- 地图风格 -->
      <bm-map-type :map-types="['BMAP_SATELLITE_MAP','BMAP_NORMAL_MAP']" anchor="BMAP_SATELLITE_TOP_LEFT"></bm-map-type>

    </baidu-map>

  </div>
</template>
<script>

export default {
  props: ['positions'],
  data() {
    return {
      zoom: 15,
      mark: this.positions,
    };
  },
  created() {
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.map {
  position: relative;
  width: 100%;
  height: 53vh;
}

/deep/.anchorBL {
  display: none;
}

.icon {
  position: absolute;
  top: 0;
  padding: 0 10px;
  font-size: 34px;
  color: rgb(5, 1, 54);
  cursor: pointer;
}
</style>