<template>
	<div id="app">
		<el-collapse v-model="dataName" accordion :disabled="false">
			<el-collapse-item title="" name="1">
				<template #title>
					<div class="map-title">
						<i class="el-icon-menu map-title"></i>
						<span v-if="deviceTypeId == 'IntelligentFireControlTerminal'">
							设备列表</span>
						<span v-else> 实时数据</span>
					</div>
				</template>
				<!-- ############ 实时数据 ############ -->

				<!-- 智慧用电设备实时数据样式 -->
				<div v-if="deviceTypeId == 'smartElectricity'" class="smart-ele">
					<!-- 智慧用电设备 -->
					<div v-for="(item, index) in changedDeviceInfoList" :key="index" class="smart-ele-1">
						<div class="image-box">
							<img :src="
                  item.name.indexOf('设备') != -1
                    ? iconList.shebei
                    : item.name.indexOf('井盖') != -1
                    ? iconList.jingai
                    : item.name.indexOf('压力') != -1
                    ? iconList.yali
                    : item.name.indexOf('功率') != -1
                    ? iconList.gonglv
                    : item.name.indexOf('电压') != -1
                    ? iconList.dianya
                    : item.name.indexOf('电流') != -1
                    ? iconList.dianliu
                    : item.name.indexOf('烟感') != -1
                    ? iconList.yangan
                    : item.name.indexOf('信号') != -1
                    ? iconList.xinhao
                    : item.name.indexOf('电池') != -1
                    ? iconList.dianchidianya
                    : iconList.shebei
                " />
						</div>
						<div class="text-box" :title="item.name + '：' + item.value">
							<span class="name">{{ item.name }}：</span>
							<span class="value">{{ item.value }}
								<span v-if="item.name.indexOf('温度')!=-1">℃</span>
								<span v-else-if="item.name.indexOf('湿度')!=-1">%RH</span>
								<span v-else-if="item.name.indexOf('压力')!=-1">MPa</span>
								<span v-else-if="item.name.indexOf('功率')!=-1">W</span>
								<span v-else-if="item.name.indexOf('电压')!=-1">V</span>
								<span v-else-if="item.name.indexOf('电流')!=-1">A</span>
								<span v-else-if="item.name.indexOf('强度')!=-1">MPa</span>
								<span v-else-if="item.name.indexOf('电量')!=-1">%</span></span>
						</div>
					</div>
				</div>

				<!-- 总线设备 -->
				<div v-else-if="deviceTypeId == 'IntelligentFireControlTerminal'">
					<!-- 总线设备实时数据 -->
					<div class="bus-equipment">
						<div class="bus-equipment-cont">
							<div class="title1">
								<span>主机设备状态： </span>
								<el-tag effect="dark" :type="
                    deviceSta == '报警'
                      ? 'danger'
                      : deviceSta == '故障'
                      ? 'warning'
                      : deviceSta == '失联'
                      ? 'info'
                      : deviceSta == '正常'
                      ? 'success'
                      : ''
                  " size="mini">{{ deviceSta }}</el-tag>
								<span style="display: inline-block; width: 72%"></span>
								<span class="iconfont icon-iotechxinhao xinhao">
									主机信号值：<span>{{
                    deviceSta == "失联" ? "无信号" : signalVal
                  }}</span></span>
							</div>
							<div class="content">
								<div v-for="(item, index) in deviceListBUS" :key="index" class="item-cont">
									<el-image style="width: 60px; height: 60px" :src="
                      item.name.indexOf('烟感') != -1
                        ? iconList.yangan
                        : item.name.indexOf('温感') != -1
                        ? iconList.wengan
                        : item.name.indexOf('手报') != -1
                        ? iconList.shoubao
                        : item.name.indexOf('声光') != -1
                        ? iconList.shenguang
                        : item.name.indexOf('输出') != -1
                        ? iconList.shuchu
                        : iconList.default
                    "></el-image>
									<div>
										<span style="font-weight: 600">{{ item.name }}</span>:
										<el-tag :type="
                        deviceSta == '失联'
                          ? 'info'
                          : item.status == '正常'
                          ? 'success'
                          : item.status == '故障'
                          ? 'warning'
                          : item.status == '报警' || item.status == '火警'
                          ? 'danger'
                          : item.status == '失联'
                          ? ''
                          : 'info'
                      " size="mini">{{ deviceSta == "失联" ? "失联" : item.status }}</el-tag>
									</div>
									<div style="margin: auto" v-if="
                      item.name.indexOf('输出') != -1 ||
                      item.name.indexOf('声光') != -1
                    ">
										<el-button @click="
                        sendCommand(item.name.substring(0, 2) + '设备启动')
                      " type="success" size="mini">启动</el-button>
										<span style="display: inline-block; width: 20px"></span>
										<el-button @click="
                        sendCommand(item.name.substring(0, 2) + '设备停止')
                      " type="primary" size="mini">停止</el-button>
									</div>
								</div>
							</div>
						</div>
						<div class="operation">
							<div class="title1">操作</div>
							<div style="height: 50px; width: 100%" v-if="deviceCommands.length != 0">
								<el-button style="margin: 5px" type="success" v-for="item in deviceCommands"
									v-if="item.contentType != 'commandData'" size="small"
									@click="sendCommand(item)">{{ item.commandName }}</el-button>
								<el-button style="margin: 5px" type="primary" v-for="item in deviceCommands"
									v-if="item.contentType == 'commandData'" size="small"
									@click="sendCommand(item)">{{ item.commandName }}</el-button>
							</div>
							<el-dialog :title="commandTitle" :visible.sync="showCommand" width="25%"
								style="top: 0%; background: rgba(0, 0, 0, 0.4)" :close-on-click-modal="false">
								<div>
									<el-input v-model="commandData" placeholder="请输入指令内容"></el-input>
								</div>
								<div slot="footer">
									<el-button @click="showCommand = false">取 消</el-button>
									<el-button type="primary" @click="submitCommand">确 定</el-button>
								</div>
							</el-dialog>
						</div>
					</div>
				</div>

				<!-- 水压 / 电信水压 -->
				<div v-else-if="
            this.deviceTypeId == 'waterPressure' ||
            this.deviceTypeId == 'DXwaterPressure'
          " class="shuiya-shuiwei">
					<div class="shuiya-1">
						<WaterPressure :data="changedDeviceInfoList" :deviceOfRow="deviceOfRow" />
					</div>

					<dv-border-box-7 class="shuiya-2">
						<div class="shuiya-2-1">
							<div>
								压力值：<span :style="
                    shuiyaStatus == '低值报警'
                      ? 'color:orange'
                      : shuiyaStatus == '高值报警'
                      ? 'color:red'
                      : 'color:green'
                  ">{{ shuiyaValue }}</span>
								MPa
							</div>
							<div>
								设备状态：<span :style="
                    shuiyaStatus == '低值报警'
                      ? 'color:orange'
                      : shuiyaStatus == '高值报警'
                      ? 'color:red'
                      : 'color:green'
                  ">{{ shuiyaStatus }}</span>
							</div>
						</div>
						<div class="description">
							<b>说明：</b> 压力值的最大值始终为当前设定的高阈值 + "<b>0.5</b>"
						</div>
						<div class="updateTime">
							数据更新时间：{{
                updateTime == "" ||
                updateTime == null ||
                updateTime == undefined
                  ? "暂无"
                  : updateTime
              }}
						</div>
					</dv-border-box-7>
				</div>

				<!-- 水位 / 电信水位 -->
				<div v-else-if="
            this.deviceTypeId == 'waterLevel' ||
            this.deviceTypeId == 'DXwaterLevel'
          " class="shuiya-shuiwei">
					<div class="shuiya-1">
						<WaterLevel :data="changedDeviceInfoList" :deviceOfRow="deviceOfRow" />
					</div>

					<dv-border-box-7 class="shuiya-2">
						<div class="shuiya-2-1">
							<div>
								水位值：<span :style="
                    shuiyaStatus == '低值报警'
                      ? 'color:orange'
                      : shuiyaStatus == '高值报警'
                      ? 'color:red'
                      : 'color:green'
                  ">{{ shuiyaValue }}</span>
								m
							</div>
							<div>
								设备状态：<span :style="
                    shuiyaStatus == '低值报警'
                      ? 'color:orange'
                      : shuiyaStatus == '高值报警'
                      ? 'color:red'
                      : 'color:green'
                  ">{{ shuiyaStatus }}</span>
							</div>
						</div>
						<div class="updateTime">
							数据更新时间：{{
                updateTime == "" ||
                updateTime == null ||
                updateTime == undefined
                  ? "暂无"
                  : updateTime
              }}
						</div>
					</dv-border-box-7>
				</div>

				<!-- 通用设备 -->
				<div class="data-box" v-else>
					<!-- 设备数据 -->
					<div class="data-boxs">
						<div class="content" v-for="(item, index) in changedDeviceInfoList" :key="index">
							<div class="image-box">
								<img :src="
                    item.name.indexOf('设备') != -1
                      ? iconList.shebei
                      : item.name.indexOf('井盖') != -1
                      ? iconList.jingai
                      : item.name.indexOf('压力') != -1
                      ? iconList.yali
                      : item.name.indexOf('功率') != -1
                      ? iconList.gonglv
                      : item.name.indexOf('电压') != -1
                      ? iconList.dianya
                      : item.name.indexOf('电流') != -1
                      ? iconList.dianliu
                      : item.name.indexOf('漏电') != -1
                      ? iconList.dianliu
                      : item.name.indexOf('配电') != -1
                      ? iconList.dianliu
                      : item.name.indexOf('烟感') != -1
                      ? iconList.yangan
                      : item.name.indexOf('信号') != -1
                      ? iconList.xinhao
                      : item.name.indexOf('温度') != -1
                      ? iconList.wendu
                      : item.name.indexOf('电池') != -1
                      ? iconList.dianchidianya
                      : iconList.shebei
                  " />
							</div>
							<div class="text-box" :title="item.name + '：' + item.value">
								<span class="name">{{ item.name }}：</span>
								<span class="value">{{ item.value }}
									<span v-if="item.name.indexOf('温度')!=-1">℃</span>
									<span v-else-if="item.name.indexOf('湿度')!=-1">%RH</span>
									<span v-else-if="item.name.indexOf('压力')!=-1">MPa</span>
									<span v-else-if="item.name.indexOf('功率')!=-1">W</span>
									<span v-else-if="item.name.indexOf('电压')!=-1">V</span>
									<span v-else-if="item.name.indexOf('电流')!=-1">A</span>
									<span v-else-if="item.name.indexOf('强度')!=-1">MPa</span>
									<span v-else-if="item.name.indexOf('电量')!=-1">%</span></span>
							</div>
						</div>
						<div class="update-time">
							数据更新时间：{{
                updateTime == "" ||
                updateTime == null ||
                updateTime == undefined
                  ? "暂无"
                  : updateTime
              }}
						</div>
					</div>
					<div class="commands">
						<div class="text">操作指令：</div>
						<div class="box" v-if="deviceCommands.length != 0">
							<div style="height: 110px; overflow: scroll">
								<el-button type="success" v-for="(item, index) in deviceCommands" :key="index"
									size="small" @click="buttonLogin(item)" :loading="isLoading"
									style="margin: 5px 10px">{{ item.commandName }}
								</el-button>
							</div>
							<!-- 指令弹框 -->
							<el-dialog :title="commandTitle" :visible.sync="showCommand" width="25%"
								style="background: rgba(0, 0, 0, 0.5)" :close-on-click-modal="false">
								<!-- 设置联系人 -->
								<div v-if="
                    deviceTypeId == 'YDAKeyToCall' &&
                    deviceCommand.commandName == '设置联系人'
                  ">
									<el-input v-model="commandPhone" placeholder="请输入联系人电话"
										style="width: 80%"></el-input>
									<div style="float: right; line-height: 40px">
										<el-button icon="el-icon-plus" circle type="primary" size="mini"
											@click="plusCommand"></el-button>
									</div>
									<div style="margin-top: 20px">
										<div v-for="(item, index) in commandDataList" :key="index"
											style="height: 30px;line-height 30px;width:100%;font-size:15px"
											class="rows">
											<span><b>联系人 {{ index + 1 }}：</b></span>
											<span>{{ item }}</span>
											<el-button icon="el-icon-minus" circle type="danger" size="mini"
												@click="minusCommand(index)" style="float: right"></el-button>
										</div>
									</div>
								</div>

								<!-- 设置定时任务 -->
								<div v-else-if="
                    deviceTypeId == 'YDAKeyToCall' &&
                    deviceCommand.commandName == '设置定时任务'
                  ">
									<div style="font-size: 15px; margin-bottom: 10px">
										<span><b>定时任务：</b></span>
										<span class="addtasks" @click="addTask">添加任务</span>
									</div>
									<div style="margin: 10px 0">
										<div>
											<b>星期（week）</b>
										</div>
										<el-checkbox v-model="taskOfWeek" v-for="(item, index) in 7" :key="index"
											:label="'星期 ' + item" size="mini" border style="margin: 5px"></el-checkbox>
									</div>
									<div style="margin: 10px 0">
										<b>小时（hour）</b>
										<el-input-number v-model="taskOfHour" :min="1" :max="24" label="小时"
											style="width: 28%"></el-input-number>&nbsp; &nbsp;
										<!-- <el-input v-model="taskOfHour" type="mumber" placeholder="小时" style="width:25%"></el-input> &nbsp; &nbsp; &nbsp; &nbsp; -->
										<b>分钟（min）</b>
										<el-input-number v-model="taskOfMin" :min="1" :max="60" label="分钟"
											style="width: 28%"></el-input-number>
										<!-- <el-input v-model="taskOfMin"  type="mumber" placeholder="分钟" style="width:25%"></el-input> -->
									</div>
									<div style="margin: 10px 0">
										<b>任务内容</b>
										<el-input type="textarea" :rows="2" placeholder="请输入任务内容" v-model="taskContent">
										</el-input>
									</div>
									<div style="margin: 10px 0">
										<b>是否启用</b>
										<el-switch v-model="taskAble" active-text="启用" inactive-text="关闭"
											active-color="#13ce66" inactive-color="#ccc"
											style="float: right"></el-switch>
									</div>
									<hr />
									<div>
										<div><b>任务列表：</b></div>
										<div>
											<div v-for="(item, index) in showTaskList" :key="index" style="
                          border-bottom: 1px solid #ccc;
                          margin-top: 5px;
                          width: 100%;
                        ">
												<div>
													<span><b>星期（week）：</b></span>
													<span style="
                              display: inline-block;
                              width: 10%;
                              color: red;
                              cursor: pointer;
                              float: right;
                            " @click="deleteTask(index)">删除</span>
													<span v-for="(items, indexs) in item.week" :key="indexs"
														v-if="items != 0">星期
														{{ items == 1 ? (items = indexs + 1) : items }},
													</span>
												</div>
												<span style="display: inline-block; width: 50%"><b>小时（hour）：</b>
													{{ item.hour }}</span>
												<span style="display: inline-block; width: 40%"><b>分钟（min）：</b>
													{{ item.min }}</span>
												<span style="display: inline-block; width: 40%"><b>是否启用</b>：
													{{ item.disable == 1 ? "不启用" : "启用" }}</span>
												<div style="display: inline-block; width: 100%">
													<b>定时任务</b>： {{ item.text }}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-else>
									<el-input v-model="commandData" placeholder="请输入指令内容"></el-input>
								</div>

								<div slot="footer">
									<el-button @click="showCommand = false">取 消</el-button>
									<el-button type="primary" @click="submitCommand">确 定</el-button>
								</div>
							</el-dialog>
						</div>
						<div v-else class="box1">暂无指令可操作</div>
					</div>
				</div>
			</el-collapse-item>
		</el-collapse>

		<!-- 如果是《总线物联网过4G中继器》设备 则不显示数据分析 -->
		<el-collapse v-model="listName" accordion :disabled="false"
			v-if="deviceTypeId != 'IntelligentFireControlTerminal'">
			<el-collapse-item title="" name="1">
				<template #title>
					<div class="map-title">
						<i class="el-icon-menu map-title"></i>
						<span> 数据分析</span>
					</div>
				</template>
				<!-- 属性折线图 -->

				<!-- 智慧用电设备属性折线图样式 -->
				<div v-if="deviceTypeId == 'smartElectricity'">
					<!-- <div class="content-left">
            <div class="title">
              <i class="el-icon-s-unfold"></i>
              <span> 设备属性列表</span>
            </div>
            <div class="content-left-btm">
              <div v-for="(item,index) in changedDeviceInfoList" :key="index" class="info-list">
              </div>
            </div>
          </div>

          <div class="content-right">
            <LineChart v-if="isShowTu" :dateList="dateList" :valueList="value1List" :hValue="hValue" :lValue="lValue" :chartTitle="chartTitle" :unit="unit" />
            <div class="div-tips" v-if="showTips">暂无数据</div>
          </div> -->
				</div>

				<!-- 通用设备 -->
				<div class="container" v-else>
					<div class="content-left">
						<div class="title">
							<i class="el-icon-s-unfold"></i>
							<span> 设备属性列表</span>
						</div>
						<div class="content-left-btm">
							<div v-for="(item, index) in changedDeviceInfoList" :key="index" class="info-list">
								<div @click="switchChart(item)">
									<span>{{ item.name }}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="content-right">
						<LineChart v-if="isShowTu" :dateList="dateList" :valueList="value1List" :hValue="hValue"
							:lValue="lValue" :chartTitle="chartTitle" :unit="unit" />
						<div class="div-tips" v-if="showTips">暂无数据</div>
					</div>
				</div>
			</el-collapse-item>
		</el-collapse>
		<!-- <div v-if="videoVisible"> -->
		<el-dialog :visible.sync="videoVisible" width="30%" title="指令操作身份验证">
			<div style="height: 200px; text-align: center">
				<!--需要弹出的内容部分-->
				<div>
					账号：<el-input v-model="userName" placeholder="请输入账号" style="width: 70%; padding: 10px"></el-input>
					<br />
					密码：<el-input v-model="possWord" placeholder="请输入密码" style="width: 70%; padding: 10px"
						show-password></el-input>
				</div>
				<div>
					<el-button @click="proof">验证</el-button>
					<el-button @click="reset">重置</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- </div> -->
	</div>
</template>

<script>
	// 水位
	import WaterLevel from "@/components/deviceClass/WaterLevel";
	// 水压
	import WaterPressure from "@/components/deviceClass/WaterPressure";
	// import HMCat1Controller from '@/api/mapBuidingApi/HMCat1Controller'
	import Command from "@/api/mapBuidingApi/Command";
	import LineChart from "@/components/echarts/lineChart";
	import deviceInfo from "@/api/manageApi/DeviceInfoManagement";
	import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
	import userAuthorityInfoController from "@/api/manageApi/UserAuthoritylnfoController";
	import jQuery from "jquery";
	import {
		Base64
	} from "js-base64";
	import $ from "jquery";
	import {
		Button
	} from "element-ui";

	export default {
		props: ["deviceId", "deviceTypeId", "deviceOfRow"],
		components: {
			LineChart,
			WaterPressure,
			WaterLevel,
		},
		data() {
			return {
				isLoading: false,
				userName: "",
				possWord: "",
				// 总线设备
				deviceListBUS: [{
						name: "",
						status: ""
					},
					{
						name: "",
						status: ""
					},
					{
						name: "",
						status: ""
					},
					{
						name: "",
						status: ""
					},
					{
						name: "",
						status: ""
					},
					{
						name: "",
						status: ""
					},
					{
						name: "",
						status: ""
					},
					{
						name: "",
						status: ""
					},
				],
				// 折叠面板激活
				//   mapName: '1',
				dataName: "1",
				listName: "1",
				// 数据更新时间
				updateTime: "",
				//   数据 list
				changedDeviceInfoList: [],
				// 心跳周期
				cycleValue: 50,
				//   信号值
				signalVal: "",
				// 设备状态
				deviceSta: "",
				// 动态属性集合
				deviceValueInfoList: [],
				// 智慧用电数据集合
				wisdomElectricity: [],
				//开闸关闸身份验证框
				videoVisible: false,

				IntervalTime: null, //查设备状态等定时器

				// 折线图要传的参数
				proList: [], //动态属性
				dateList: [], //折线图时间显示数组
				value1List: [], //折线图值显示数组
				hValue: "", //折线图高阈值显示
				lValue: "", //折线图低阈值显示
				chartTitle: "", //折线图标题
				unit: "", //折线图单位显示
				isShowTu: false, //是否显示折线图,
				showTips: false, //无折线图时的提示，
				showChartDeviceId: "", //显示折线图的设备id
				showChartDeviceTypeId: "", //显示折线图的设备类型id

				deviceCommands: [], // 设备指令
				deviceCommand: {}, //设备指令信息
				commandTitle: "",
				commandData: "",
				commandPhone: "",
				commandDataList: [],

				showCommand: false, // 有数据的指令下发
				command: {},
				item1: {},
				iconList: {
					yangan: require("../../../assets/img/icon1/yangan.png"),
					yali: require("../../../assets/img/icon1/yali.png"),
					xinhao: require("../../../assets/img/icon1/xinhao.png"),
					shebei: require("../../../assets/img/icon1/shebei.png"),
					jingai: require("../../../assets/img/icon1/jingai1.png"),
					gonglv: require("../../../assets/img/icon1/gonglv.png"),
					dianya: require("../../../assets/img/icon1/dianya.png"),
					dianliu: require("../../../assets/img/icon1/dianliu.png"),
					dianchidianya: require("../../../assets/img/icon1/dianchidianya.png"),
					wengan: require("../../../assets/img/icon/wengan.png"),
					wendu: require("../../../assets/img/wendu.png"),
					shoubao: require("../../../assets/img/icon/shoubao.png"),
					shenguang: require("../../../assets/img/icon/shenguang.png"),
					shuchu: require("../../../assets/img/icon/shuchu.png"),
					default: require("../../../assets/img/icon/default.png"),
				},

				// 设置定时任务
				taskOfWeek: [], // 选择星期
				taskOfHour: "", // 选择小时
				taskOfMin: "", // 选择分钟
				// 添加的任务列表
				taskList: [],
				showTaskList: [], // 显示定时任务
				taskContent: "", //任务内容
				taskAble: 0, // 是否启用  0启用
			};
		},

		created() {
			// 根据设备 ID 查设备动态属性
			this.getDeviceValue();
			this.IntervalTime = setInterval(() => {
				this.changeDeviceStatus();
			}, 5000);

			// 根据设备 ID 查设备的经纬度
			// this.getDeviceType();

			// 显示折线图
			this.showCharts();

			this.getDeviceCmd();
		},
		destroyed() {
			clearInterval(this.IntervalTime);
		},

		computed: {
			shuiyaValue() {
				for (let i = 0; i < this.changedDeviceInfoList.length; i++) {
					if (this.changedDeviceInfoList[i].name == "值") {
						return this.changedDeviceInfoList[i].value;
						break;
					}
				}
			},
			shuiyaStatus() {
				for (let i = 0; i < this.changedDeviceInfoList.length; i++) {
					if (this.changedDeviceInfoList[i].name == "值") {
						for (let j = 0; j < this.deviceOfRow.device.length; j++) {
							if (this.deviceOfRow.device[j].proName == "低阈值") {
								if (
									this.changedDeviceInfoList[i].value <
									this.deviceOfRow.device[j]["value"]
								) {
									return "低值报警";
								}
							} else if (this.deviceOfRow.device[j].proName == "高阈值") {
								if (
									this.changedDeviceInfoList[i].value >
									this.deviceOfRow.device[j]["value"]
								) {
									return "高值报警";
								}
								return;
							} else {
								return "正常";
							}
						}
						return this.changedDeviceInfoList[i].value;
						break;
					}
				}
			},
		},
		methods: {
			//操作指令身份验证
			proof() {
				console.log(this.item1);
				userAuthorityInfoController
					.verification(this.userName, this.possWord)
					.then((res) => {
						console.log(res.data.message);
						if (res.data.message == "成功") {
							this.sendCommand(this.item1);
							this.videoVisible = false;
						}
						if (res.data.message != "成功") {
							console.log(1111111111);
							this.$message.warning("身份验证失败！");
						}
					});
			},
			//   指令下发
			async sendCommand(item) {
				this.commandData = "";
				this.deviceCommand = item;
				this.commandTitle = item.commandName;
				this.command["deviceId"] = this.deviceId;
				if (
					this.deviceTypeId == "IntelligentFireControlTerminal" &&
					typeof item == "string" &&
					item.constructor == String
				) {
					let obj = {};
					obj.contentType = "commandNoData";
					obj.paramValue = item;
					this.command["deviceCommand"] = obj;
				} else {
					this.command["deviceCommand"] = item;
				}
				if (item.contentType == "commandData") {
					if (
						this.deviceTypeId == "YDAKeyToCall" &&
						item.commandName == "设置联系人"
					) {
						this.isLoading = true;
						let obj = {};
						obj.deviceId = this.deviceId;
						obj.value = "emergency_contact";
						const {
							data: res
						} = await Command.sendCommand(obj);
						if (res.message == "成功") {
							this.isLoading = false;
						}
						this.commandDataList = res.data.value.split(",");
					} else if (
						this.deviceTypeId == "YDAKeyToCall" &&
						item.commandName == "设置定时任务"
					) {
						this.isLoading = true;
						let obj = {};
						obj.deviceId = this.deviceId;
						obj.value = "timer_tasks";
						const {
							data: res
						} = await Command.sendCommand(obj);
						if (res.message == "成功") {
							this.isLoading = false;
						}
						this.commandData = res.data.value;
						this.settings(this.commandData);
					}
					this.showCommand = true;
				} else {
					this.commandSend(this.command);
				}
			},

			//重置按钮
			reset() {
				this.userName = "";
				this.possWord = "";
			},
			// 根据设备类型 id 查设备指令
			async getDeviceCmd() {
				const {
					data: res
				} = await Command.getDeviceCommand(this.deviceTypeId);
				this.deviceCommands = res.data.deviceCommands;
			},

			deleteTask(index) {
				this.commandData.splice(index, 1);
				this.showTaskList.splice(index, 1);
				// this.commandData.splice(index, 1)
			},

			// 有数据的指令下发
			submitCommand() {
				if (typeof this.commandData == "string" && this.commandData == "") {
					this.$message.warning("指令内容不能为空！");
				} else if (
					typeof this.commandData == "object" &&
					this.commandData.length == 0
				) {
					this.$message.warning("指令内容不能为空！");
				} else {
					this.deviceCommand.paramValue = this.commandData;
					this.command["deviceId"] = this.deviceId;
					this.command["deviceCommand"] = this.deviceCommand;
					this.showCommand = false;
					this.commandSend(this.command);
					this.commandData = "";
				}
			},

			//
			buttonLogin(item) {
				this.item1 = item;
				this.videoVisible = true;
			},

			// 指令添加联系人
			plusCommand() {
				if (this.commandPhone == "") {
					this.$message.warning("指令内容不能为空！");
				} else {
					let reg = /^1[0-9]{10}$/;
					if (!reg.test(this.commandPhone)) {
						this.$message.error("请输入正确手机号!");
						return;
					} else {
						this.commandDataList.push(this.commandPhone);
						this.commandPhone = "";
						this.commandData = this.commandDataList.toString();
					}
				}
			},

			// 指令删除联系人
			minusCommand(index) {
				this.commandDataList.splice(index, 1);
			},

			// 添加定时任务
			addTask() {
				if (this.taskOfWeek.length == 0) {
					this.$message.warning("请选择星期！");
				} else if (this.taskContent == "") {
					this.$message.warning("任务内容不能为空！");
				} else {
					let arr = [];
					let num = 0;
					for (let i = 0; i < this.taskOfWeek.length; i++) {
						arr.push(this.taskOfWeek[i].split(" ").pop());
					}
					let week = "00000000";
					for (let i = 0; i < arr.length; i++) {
						if (arr[i] != 1) {
							week =
								week.substring(0, 8 - arr[i]) + "1" + week.substring(9 - arr[i]);
						} else {
							week = week.substring(0, 8 - arr[i]) + "1";
						}
					}
					let obj = {};
					obj.week = parseInt(Number(week), 2);
					obj.hour = this.taskOfHour;
					obj.min = this.taskOfMin;
					obj.text = Base64.encode(this.taskContent);
					obj.disable = this.taskAble ? 0 : 1;

					this.commandData.push(obj);
					this.settings(this.commandData);
					// 添加后清空
					this.taskOfWeek = [];
					this.taskContent = "";
					this.taskOfHour = "";
					this.taskOfMin = "";
				}
			},

			// 添加回显
			settings(arr) {
				let list = JSON.stringify(arr);
				this.showTaskList = JSON.parse(list);
				// this.showTaskList = Object.assign(this.commandData)
				for (let i = 0; i < this.showTaskList.length; i++) {
					// week 转二进制
					this.showTaskList[i].week = this.showTaskList[i].week.toString(2);
					this.showTaskList[i].week = this.showTaskList[i].week
						.split("")
						.reverse()
						.join("");
					// base64解码
					this.showTaskList[i].text = Base64.decode(this.showTaskList[i].text);
				}
			},

			async commandSend(command) {
				if (
					command.deviceCommand.typeId == "YDAKeyToCall" &&
					command.deviceCommand.paramName == "timer_tasks"
				) {
					command.timer_tasks = this.commandData;
				}
				const {
					data: res
				} = await Command.sendCommand(command);
				if (res.message == "指令发送成功") {
					this.$message({
						message: "指令发送成功！",
						type: "success",
					});
					this.reloadData();
				} else if (res.message == "无此设备的指令下发权限") {
					this.$message({
						message: "无此设备的指令下发权限",
						type: "error",
					});
				} else {
					this.$message({
						message: res.message,
						type: "error",
					});
				}
			},
			// 开关闸之后刷新数据
			reloadData() {
				// 获取数据
				this.getDeviceValue();
			},
			// 设备属性列表事件
			switchChart(item) {
				if (item.name) {
					if (item.name.indexOf("状态") != -1) {
						this.$message.warning("无设备状态数据！");
						this.isShowTu = false;
						this.showTips = true;
					} else {
						item = item.name;
						this.isShowTu = false;
						this.chartTitle = item;
						deviceInfo
							.getDayValue(
								this.showCharDeviceId,
								"",
								"",
								this.showChartDeviceTypeId,
								this.showCharDeviceId + ".value." + item
							)
							.then((res) => {
								if (res.data.message == "不显示") {
									this.isShowTu = false;
									this.showTips = true;
								} else {
									this.dateList = res.data.data.dateList;
									this.value1List = res.data.data.valueList;
									if (
										res.data.data.hValue != undefined &&
										res.data.data.hValue != null
									) {
										this.hValue = res.data.data.hValue;
										this.lValue = res.data.data.lValue;
									}
									if (
										res.data.data.unit != "" &&
										res.data.data.unit != undefined
									) {
										this.unit = res.data.data.unit;
									}
									this.isShowTu = true;
									this.showTips = false;
								}
							});
					}
				}
			},
			showCharts() {
				this.proList = [];
				this.chartTitle = "";
				this.isShowTu = false;
				this.showTips = true;
				this.showCharDeviceId = this.deviceId;
				this.showChartDeviceTypeId = this.deviceTypeId;
				deviceInfo.getDynamicPro(this.deviceTypeId).then((res) => {
					let _this = this;
					for (const i in res.data.data.list) {
						if (
							(res.data.data.list[i].indexOf("值") != -1 ||
								res.data.data.list[i].indexOf("电压") != -1 ||
								res.data.data.list[i].indexOf("电流") != -1) &&
							res.data.data.list[i].indexOf("状态") == -1 &&
							res.data.data.list[i].indexOf("阈值") == -1
						) {
							this.proList.push(res.data.data.list[i]);
						}
					}
					if (this.proList.length == 1 || this.proList.length > 1) {
						deviceInfo
							.getDayValue(
								this.deviceId,
								"",
								"",
								this.deviceTypeId,
								this.deviceId + ".value." + this.proList[0]
							)
							.then((res) => {
								if (res.data.message == "不显示") {
									_this.isShowTu = false;
									this.showTips = true;
								} else {
									_this.dateList = res.data.data.dateList;
									_this.value1List = res.data.data.valueList;
									if (
										res.data.data.hValue != undefined &&
										res.data.data.hValue != null
									) {
										_this.hValue = res.data.data.hValue;
										_this.lValue = res.data.data.lValue;
									}
									if (
										res.data.data.unit != "" &&
										res.data.data.unit != undefined
									) {
										this.unit = res.data.data.unit;
									}
									this.chartTitle = this.proList[0];
									_this.isShowTu = true;
									this.showTips = false;
								}
							});
					}
				});
			},
			//改变设备的状态
			async changeDeviceStatus() {
				//如果是智慧用电相关设备，对数据单独处理
				if (this.deviceTypeId == "smartElectricity") {} else if (this.deviceTypeId ==
					"IntelligentFireControlTerminal") {
					// 《总线物联网4G中继器》设备数据处理
					const {
						data: res
					} =
					await deviceInfoManagement.getDeviceInfoValueByDeviceId(
						this.deviceId
					);
					this.deviceValueInfoList = res.data.list;
					this.busEquipmentMethod();
				} else {
					const {
						data: res
					} =
					await deviceInfoManagement.getDeviceInfoByDeviceId(
						this.deviceId,
						1,
						1
					);
					this.deviceValueInfoList = [];
					let lastDataTime = res.data.deviceDynamicVo["lastDataTime"];
					let createDeviceTime = res.data.deviceDynamicVo["createDeviceTime"];
					let isOnline = true;
					if (lastDataTime == null || createDeviceTime > lastDataTime) {
						isOnline = false;
					}
					for (const i in res.data.deviceDynamicVo["nameList"]) {
						let obj = {};
						obj.id = res.data.deviceDynamicVo["nameList"][i];
						obj.value = res.data.deviceDynamicVo["valueList"][i];
						this.deviceValueInfoList.push(obj);
					}
					//其它类型设备走下面正常处理
					let deviceStatus = "";
					for (const i in this.deviceValueInfoList) {
						if (
							(this.deviceValueInfoList[i]["id"].indexOf("状态") != -1 ||
								this.deviceValueInfoList[i]["id"] == "报警类型") &&
							this.deviceValueInfoList[i]["value"] != "" &&
							((this.deviceValueInfoList[i]["value"] == "正常" &&
									deviceStatus == "") ||
								this.deviceValueInfoList[i]["value"] != "正常") &&
							this.deviceValueInfoList[i]["value"] != null &&
							this.deviceValueInfoList[i]["value"] != undefined
						) {
							if (
								(this.deviceValueInfoList[i]["value"].indexOf("正常") != -1 &&
									this.deviceValueInfoList[i]["value"].indexOf("故障") == -1 &&
									this.deviceValueInfoList[i]["value"].indexOf("警") == -1) ||
								this.deviceValueInfoList[i]["value"].indexOf("无") != -1 ||
								this.deviceValueInfoList[i]["value"].indexOf("解除") != -1
							) {
								deviceStatus = "正常";
							} else if (this.deviceValueInfoList[i]["value"].indexOf("失联") != -1) {
								deviceStatus = "失联";
								break;
							} else {
								deviceStatus = this.deviceValueInfoList[i]["value"];
							}
						}
					}
					let isLost = false; //判断电信设备是否离线等
					if (this.deviceTypeId.indexOf("DX") != -1) {
						const {
							data: ress
						} =
						await deviceInfoManagement.queryAEPDeviceStatus(
							this.deviceTypeId,
							this.deviceId
						);
						if (ress.data.status == "离线") {
							deviceStatus = "离线";
							isLost = true;
						} else if (ress.data.status == "已注册") {
							deviceStatus = "未激活";
							isLost = true;
						} else if (ress.data.status == "已注销") {
							deviceStatus = "已注销";
							isLost = true;
						}
					}
					if (isLost == false) {
						if (deviceStatus != "" && isOnline == true) {} else if (deviceStatus == "" && isOnline ==
							true) {
							deviceStatus = "正常";
						} else {
							if (
								this.deviceId.indexOf("IOE") != -1 ||
								this.deviceId.indexOf("_") != -1 ||
								this.deviceTypeId == "DXHippoAcoustoOptic" ||
								this.deviceTypeId == "numPressureGauge"
							) {
								if (deviceStatus == "") {
									deviceStatus = "正常";
								}
							} else {
								deviceStatus = "未服务";
								isLost = true;
							}
						}
					}
					for (const i in this.changedDeviceInfoList) {
						if (this.changedDeviceInfoList[i].name.indexOf("状态") != -1) {
							this.changedDeviceInfoList[i].value = deviceStatus;
						}
					}
				}
			},
			async getDeviceValue() {
				this.changedDeviceInfoList = []
				//如果是智慧用电相关设备，对数据单独处理
				if (this.deviceTypeId == "smartElectricity") {
					const {
						data: res
					} =
					await deviceInfoManagement.getDeviceInfoValueByDeviceId(
						this.deviceId
					);
					this.deviceValueInfoList = res.data.list;
					// 智慧用电数据处理方法
					this.wisdomElectricityInfo();

					// 《总线物联网4G中继器》设备数据处理
				} else if (this.deviceTypeId == "IntelligentFireControlTerminal") {
					const {
						data: res
					} =
					await deviceInfoManagement.getDeviceInfoValueByDeviceId(
						this.deviceId
					);
					this.deviceValueInfoList = res.data.list;
					this.busEquipmentMethod();
				} else {
					const {
						data: res
					} =
					await deviceInfoManagement.getDeviceInfoByDeviceId(
						this.deviceId,
						1,
						1
					);
					this.deviceValueInfoList = [];
					this.updateTime = res.data.deviceDynamicVo.lastDataTime;
					let lastDataTime = res.data.deviceDynamicVo["lastDataTime"];
					let createDeviceTime = res.data.deviceDynamicVo["createDeviceTime"];
					let isOnline = true;
					if (lastDataTime == null || createDeviceTime > lastDataTime) {
						isOnline = false;
					}
					for (const i in res.data.deviceDynamicVo["nameList"]) {
						let obj = {};
						obj.id = res.data.deviceDynamicVo["nameList"][i];
						obj.value = res.data.deviceDynamicVo["valueList"][i];
						this.deviceValueInfoList.push(obj);
					}
					//其它类型设备走下面正常处理
					let typeMap = new Map([
						["井盖", {
							name: [],
							value: []
						}],
						["压力", {
							name: [],
							value: []
						}],
						["电池", {
							name: [],
							value: []
						}],
						["水位", {
							name: [],
							value: []
						}],
					]);
					let typeList = ["井盖", "压力", "电池", "水位"];
					this.showDeviceInfoList = []; //清空数据
					let deviceStatus = "";
					let nameList = [];
					let valueList = [];
					let obj = {};
					for (const i in this.deviceValueInfoList) {
						nameList = [];
						valueList = [];
						console.log(this.deviceValueInfoList[i]["id"]);
						if (typeMap.has(this.deviceValueInfoList[i]["id"].substring(0, 2))) {
							typeMap
								.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["name"].push(this
									.deviceValueInfoList[i]["id"]);
							typeMap
								.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["value"].push(this
									.deviceValueInfoList[i]["value"]);
						} else if (
							(this.deviceValueInfoList[i]["id"].indexOf("状态") != -1 ||
								this.deviceValueInfoList[i]["id"] == "报警类型") &&
							this.deviceValueInfoList[i]["value"] != "" &&
							((this.deviceValueInfoList[i]["value"] == "正常" &&
									deviceStatus == "") ||
								this.deviceValueInfoList[i]["value"] != "正常") &&
							this.deviceValueInfoList[i]["value"] != null &&
							this.deviceValueInfoList[i]["value"] != undefined
						) {
							if (
								(this.deviceValueInfoList[i]["value"].indexOf("正常") != -1 &&
									this.deviceValueInfoList[i]["value"].indexOf("故障") == -1 &&
									this.deviceValueInfoList[i]["value"].indexOf("警") == -1) ||
								this.deviceValueInfoList[i]["value"].indexOf("无") != -1 ||
								this.deviceValueInfoList[i]["value"].indexOf("解除") != -1
							) {
								deviceStatus = "正常";
							} else if (this.deviceValueInfoList[i]["value"].indexOf("失联") != -1) {
								deviceStatus = "失联";
								break;
							} else {
								deviceStatus = this.deviceValueInfoList[i]["value"];
							}
						}
						// 如果有下列属性中的一种
						else if (
							(this.deviceValueInfoList[i]["id"].indexOf("值") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("压力") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("电流") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("电压") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("角度") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("电量") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("功率") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("用水") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("用电") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("强度") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("温度") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("漏电") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("配电") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("开关") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("信号") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("信噪比") != -1 ||
								this.deviceValueInfoList[i]["id"].indexOf("浓度") != -1) &&
							// 并且有以下三种属性
							this.deviceValueInfoList[i]["id"].indexOf("状态") == -1 &&
							this.deviceValueInfoList[i]["id"].indexOf("记录") == -1 &&
							this.deviceValueInfoList[i]["id"].indexOf("时间") == -1
						) {
							nameList.push(this.deviceValueInfoList[i]["id"]);
							if (
								this.deviceValueInfoList[i]["value"] == "" ||
								this.deviceValueInfoList[i]["value"] == null ||
								this.deviceValueInfoList[i]["value"] == undefined
							) {
								valueList.push("暂无");
							} else {
								valueList.push(this.deviceValueInfoList[i]["value"]);
							}
							obj["nameList"] = nameList;
							obj["valueList"] = valueList;
							this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)));
						}
					}
					nameList = [];
					valueList = [];
					let isLost = false; //判断电信设备是否离线等
					if (this.deviceTypeId.indexOf("DX") != -1) {
						const {
							data: ress
						} =
						await deviceInfoManagement.queryAEPDeviceStatus(
							this.deviceTypeId,
							this.deviceId
						);
						if (ress.data.status == "离线") {
							nameList.push("设备状态");
							valueList.push("离线");
							isLost = true;
						} else if (ress.data.status == "已注册") {
							nameList.push("设备状态");
							valueList.push("未激活");
							isLost = true;
						} else if (ress.data.status == "已注销") {
							nameList.push("设备状态");
							valueList.push("已注销");
							isLost = true;
						}
					}
					if (isLost == false) {
						if (deviceStatus != "" && isOnline == true) {
							nameList.push("设备状态");
							valueList.push(deviceStatus);
						} else if (deviceStatus == "" && isOnline == true) {
							nameList.push("设备状态");
							valueList.push("正常");
						} else {
							if (
								this.deviceId.indexOf("IOE") != -1 ||
								this.deviceId.indexOf("_") != -1 ||
								this.deviceTypeId == "DXHippoAcoustoOptic" ||
								this.deviceTypeId == "numPressureGauge"
							) {
								if (deviceStatus != "") {
									nameList.push("设备状态");
									valueList.push(deviceStatus);
								} else {
									nameList.push("设备状态");
									valueList.push("正常");
								}
							} else {
								nameList.push("设备状态");
								valueList.push("未服务");
								isLost = true;
							}
						}
					}
					obj["nameList"] = nameList;
					obj["valueList"] = valueList;
					this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)));
					let mapStatus = ""; //用于特殊类型状态显示
					for (const i in typeList) {
						obj = {};
						if (typeMap.get(typeList[i]).name.length != 0) {
							let status = "";
							nameList = [];
							valueList = [];
							for (const ii in typeMap.get(typeList[i]).name) {
								if (
									typeMap.get(typeList[i]).name[ii].indexOf("状态") != -1 &&
									typeMap.get(typeList[i]).value[ii] != "" &&
									((typeMap.get(typeList[i]).value[ii] == "正常" &&
											status == "") ||
										typeMap.get(typeList[i]).value[ii] != "正常") &&
									typeMap.get(typeList[i]).value[ii] != null &&
									typeMap.get(typeList[i]).value[ii] != undefined
								) {
									status = typeMap.get(typeList[i]).value[ii];
									if (status != null && status != "" && status != "正常") {
										mapStatus = status;
									}
								} else if (
									(typeMap.get(typeList[i]).name[ii].indexOf("值") != -1 ||
										typeMap.get(typeList[i]).name[ii].indexOf("压力") != -1 ||
										typeMap.get(typeList[i]).name[ii].indexOf("电流") != -1 ||
										typeMap.get(typeList[i]).name[ii].indexOf("电压") != -1 ||
										typeMap.get(typeList[i]).name[ii].indexOf("角度") != -1 ||
										typeMap.get(typeList[i]).name[ii].indexOf("电量") != -1) &&
									typeMap.get(typeList[i]).name[ii].indexOf("状态") == -1 &&
									typeMap.get(typeList[i]).name[ii].indexOf("记录") == -1 &&
									typeMap.get(typeList[i]).name[ii].indexOf("时间") == -1
								) {
									nameList.push(typeMap.get(typeList[i]).name[ii]);
									if (
										typeMap.get(typeList[i]).value[ii] == "" ||
										typeMap.get(typeList[i]).value[ii] == null ||
										typeMap.get(typeList[i]).value[ii] == undefined
									) {
										valueList.push("暂无");
									} else {
										valueList.push(typeMap.get(typeList[i]).value[ii]);
									}
								}
							}
							obj["nameList"] = nameList;
							obj["valueList"] = valueList;
							this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)));
						}
					}
					for (let i = 0; i < this.showDeviceInfoList.length; i++) {
						for (let j = 0; j < this.showDeviceInfoList[i].nameList.length; j++) {
							let obj = {};
							obj.name = this.showDeviceInfoList[i].nameList[j];
							if (
								this.showDeviceInfoList[i].nameList[j] == "设备状态" &&
								this.showDeviceInfoList[i].valueList[j] == "正常" &&
								mapStatus != "" &&
								mapStatus != "正常"
							) {
								obj.value = mapStatus;
							} else {
								obj.value = this.showDeviceInfoList[i].valueList[j];
							}
							this.changedDeviceInfoList.push(obj);
						}
					}

					if (isLost) {
						let length = this.changedDeviceInfoList.length;
						for (let i = 0; i < length; i++) {
							if (this.changedDeviceInfoList[i].name.indexOf("状态") == -1) {
								this.changedDeviceInfoList.splice(i, 1);
								i--;
							}
						}
					}
				}
			},
			// 智慧用电设备数据处理
			wisdomElectricityInfo() {
				let num = 1;
				let obj = {};
				let nameList = [];
				let valueList = [];
				let status = "";
				let type = "";
				for (let i = 0; i < this.deviceValueInfoList.length; i++) {
					if (this.deviceValueInfoList[i].id.indexOf("CH") != -1) {
						if (this.deviceValueInfoList[i].id.indexOf("CH" + num) != -1) {
							if (this.deviceValueInfoList[i].id.indexOf("通道类型") != -1) {
								type = this.deviceValueInfoList[i].value;
							} else if (
								this.deviceValueInfoList[i].id.indexOf("报警") == -1 &&
								this.deviceValueInfoList[i].id.indexOf("故障") == -1 &&
								this.deviceValueInfoList[i].id.indexOf("失联") == -1 &&
								this.deviceValueInfoList[i].id.indexOf("开关状态") == -1
							) {
								if (this.deviceValueInfoList[i].value == "") {
									nameList.push(this.deviceValueInfoList[i].id);
									valueList.push("暂无");
								} else {
									nameList.push(this.deviceValueInfoList[i].id);
									valueList.push(this.deviceValueInfoList[i].value);
								}
							} else {
								status = this.deviceValueInfoList[i].value;
							}
						} else {
							for (let ii = 0; ii < nameList.length; ii++) {
								if (nameList[ii].indexOf("检测值") != -1 && type != "") {
									obj["name"] = nameList[ii];
									nameList[ii] = nameList[ii].replace("检测", type);
								}
							}
							if (status != "" && status != null && status != undefined) {
								nameList.push("设备状态");
								valueList.push(status);
							} else {
								nameList.push("设备状态");
								valueList.push("离线");
							}
							obj["nameList"] = nameList;
							obj["valueList"] = valueList;
							let obj11 = JSON.parse(JSON.stringify(obj));
							this.wisdomElectricity.push(obj11);
							obj = {};
							nameList = [];
							valueList = [];
							num++;
							if (this.deviceValueInfoList[i].id.indexOf("通道类型") != -1) {
								type = this.deviceValueInfoList[i].value;
							} else if (
								this.deviceValueInfoList[i].id.indexOf("报警") == -1 &&
								this.deviceValueInfoList[i].id.indexOf("故障") == -1 &&
								this.deviceValueInfoList[i].id.indexOf("失联") == -1 &&
								this.deviceValueInfoList[i].id.indexOf("开关状态") == -1 &&
								this.deviceValueInfoList[i].value != ""
							) {
								nameList.push(this.deviceValueInfoList[i].id);
								valueList.push(this.deviceValueInfoList[i].value);
							} else {
								status = this.deviceValueInfoList[i].value;
							}
						}
						if (i == this.deviceValueInfoList.length - 1) {
							for (let ii = 0; ii < nameList.length; ii++) {
								if (nameList[ii].indexOf("检测值") != -1 && type != "") {
									obj["name"] = nameList[ii];
									nameList[ii] = nameList[ii].replace("检测", type);
								}
							}
							if (status != "" && status != null && status != undefined) {
								nameList.push("设备状态");
								valueList.push(status);
							} else {
								nameList.push("设备状态");
								valueList.push("离线");
							}
							obj["nameList"] = nameList;
							obj["valueList"] = valueList;
							let obj11 = JSON.parse(JSON.stringify(obj));
							this.wisdomElectricity.push(obj11);
						}
					}
				}
				//   this.changedDeviceInfoList = [];
				for (let i = 0; i < this.wisdomElectricity.length; i++) {
					let arr = [];
					let obj1 = {};
					for (let j = 0; j < this.wisdomElectricity[i].nameList.length; j++) {
						let obj = {};
						obj.name = this.wisdomElectricity[i].nameList[j];
						obj.value = this.wisdomElectricity[i].valueList[j];
						arr.push(obj);

						// 处理CHN设备状态
						for (let k = 0; k < arr.length; k++) {
							if (arr[k]["name"] == "设备状态") {
								let aa = arr[k - 1]["name"].split("-");
								arr[k]["name"] = aa[0] + "-设备状态";
							}
						}
					}
					obj1.list = arr;
					this.changedDeviceInfoList.push(obj1);
				}

				let l = this.changedDeviceInfoList.length;
				let arrs = [];
				for (let i = 0; i < this.changedDeviceInfoList.length; i++) {
					for (let j = 0; j < this.changedDeviceInfoList[i].list.length; j++) {
						arrs.push(this.changedDeviceInfoList[i].list[j]);
					}
				}
				this.changedDeviceInfoList = arrs;
				console.log(this.changedDeviceInfoList);
			},

			// 总线物联网4G中继器数据处理
			busEquipmentMethod() {
				for (let i = 0; i < this.deviceValueInfoList.length; i++) {
					if (this.deviceValueInfoList[i].id == "失联状态") {
						this.deviceSta = this.deviceValueInfoList[i].value;
					}
					if (this.deviceValueInfoList[i].id == "信号值") {
						this.signalVal = this.deviceValueInfoList[i].value;
					}
					if (this.deviceValueInfoList[i].id == "心跳周期") {
						this.cycleValue = Number(this.deviceValueInfoList[i].value);
					}
					let num = Number.parseInt(
						this.deviceValueInfoList[i]["id"].substring(0, 1)
					);
					num--;
					if (this.deviceValueInfoList[i]["id"].indexOf("设备类型") != -1) {
						this.deviceListBUS[num].name =
							++num + "号" + this.deviceValueInfoList[i]["value"];
					}
					if (this.deviceValueInfoList[i]["id"].indexOf("报警状态") != -1) {
						this.deviceListBUS[num].status = "火警";
					} else if (
						this.deviceValueInfoList[i]["id"].indexOf("故障状态") != -1
					) {
						this.deviceListBUS[num].status = "故障";
					} else if (
						this.deviceValueInfoList[i]["id"].indexOf("设备状态") != -1
					) {
						if (
							this.deviceValueInfoList[i]["value"].indexOf("解除") != -1 ||
							this.deviceValueInfoList[i]["value"].indexOf("正常") != -1
						) {
							this.deviceListBUS[num].status = "正常";
						} else if (this.deviceValueInfoList[i]["value"].indexOf("警") != -1) {
							this.deviceListBUS[num].status = "火警";
						} else if (
							this.deviceValueInfoList[i]["value"].indexOf("故障") != -1
						) {
							this.deviceListBUS[num].status = "故障";
						} else if (
							this.deviceValueInfoList[i]["value"].indexOf("暂无") != -1
						) {
							this.deviceListBUS[num].status = "暂无";
						}
					}
				}
				this.$set(this.deviceListBUS);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.data-box {
		box-sizing: border-box;
		width: 100%;
		height: 260px;
		background: #fff;
		height: auto;
		display: flex;

		.commands {
			width: 20%;
			margin: 0 0 0 10px;
			box-shadow: 0px 0px 5px #ccc;
			padding: 5px;

			.text {
				font-size: 16px;
				color: #096863;
				font-weight: 600;
			}

			.box {
				width: 100%;
			}

			.box1 {
				width: 100%;
				text-align: center;
				line-height: 60px;
				color: #999;
				user-select: none;
			}
		}

		.data-boxs {
			display: flex;
			flex-wrap: wrap;
			width: 80%;
			height: 150px;
			box-shadow: 0px 0px 5px #ccc;
			overflow: scroll;

			.content {
				cursor: pointer;
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;
				width: 20%;
				margin: 10px 0px;
				padding: 10px;
				border-radius: 5px;

				.image-box {
					img {
						width: 60px;
					}
				}

				.text-box {
					font-size: 16px;
					height: 55px;
					line-height: 55px;
					padding-left: 10px;

					width: 72%;
					overflow: hidden;
					text-overflow: ellipsis; //显示 ...
					white-space: nowrap; //不换行

					// 实时数据值
					.value {
						color: #7dc5eb;
						font-weight: 600;
					}
				}
			}

			.content:hover {
				background: rgba(0, 0, 0, 0.1);
				transition: 1s;
			}
		}
	}

	.update-time {
		box-sizing: border-box;
		width: 100%;
		padding: 0 20px;
		font-size: 14px;
		color: #999;
		text-align: right;
	}

	// /deep/.el-collapse-item__header {
	//   letter-spacing: 3px;
	// }
	/deep/.el-collapse-item__content {
		padding: 0;
	}

	.rows:hover {
		cursor: pointer;
		background: rgba(94, 112, 114, 0.1);
	}

	.title {
		background: #eff0f0;
		height: 50px;
		line-height: 55px;
		font-size: 18px;
		font-weight: 600;
		color: cadetblue;
		padding-left: 10px;
	}

	.map-title {
		background: #eff0f0;
		height: 50px;
		line-height: 55px;
		font-size: 18px;
		font-weight: 600;
		color: cadetblue;
		padding-left: 5px;
	}

	.container {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		margin: 0 5px;
		background: #fff;

		.content-left {
			width: 15%;
			height: 380px;
			box-shadow: 1px 1px 3px #ccc;

			.title {
				font-size: 16px;
				background: rgb(252, 250, 250);
			}

			.content-left-btm {
				scrollbar-width: none;
				height: 380px - 50px;
				overflow: scroll;

				.info-list {
					box-sizing: border-box;
					// margin: 0 10px;
					height: 50px;
					line-height: 50px;
					padding-left: 25px;
					border-bottom: 1px dashed #ddd;
					cursor: pointer;
				}

				.info-list:hover {
					background: #eee;
				}
			}
		}

		.content-right {
			box-sizing: border-box;
			width: 86%;
			padding: 0 10px;

			.div-tips {
				width: 100%;
				font-size: 22px;
				width: 100%;
				text-align: center;
				padding-top: 120px;
				color: #aaa;
			}
		}
	}

	// 总线设备样式
	.bus-equipment {
		width: 100%;
		height: 200px;
		display: flex;

		box-sizing: border-box;

		.bus-equipment-cont {
			width: 75%;
			// margin: 0 10px;
			padding: 0 10px;

			.title1 {
				padding: 10px;
				font-size: 16px;
				font-weight: 600;
				color: cadetblue;
				letter-spacing: 2px;
				border-bottom: 1px dashed #eee;
			}

			.content {
				width: 100%;
				display: flex;

				.item-cont {
					// width: 100%;
					display: flex;
					flex-wrap: wrap;
					margin-top: 10px;
					padding-top: 10px;
					height: 127px;
					line-height: 60px;
					box-shadow: 0px 1px 5px #eee;
					// margin: 3px;

					width: 502px;

					div {
						padding-left: 5px;
					}
				}
			}
		}

		.operation {
			width: 25%;
			margin: 0 10px;
			box-shadow: 0px 1px 3px #eee;

			// border: 1px solid pink;
			.title1 {
				padding: 10px;
				font-size: 16px;
				font-weight: 600;
				color: cadetblue;
				letter-spacing: 2px;
				border-bottom: 1px dashed #eee;
			}
		}
	}

	.xinhao {
		width: 100%;
		color: rgb(19, 183, 233);

		span {
			color: #08cec4;
			font-weight: 900;
			font-size: 18px;
		}
	}

	/deep/.el-tag--mini {
		height: 24px;
		line-height: 24px;
	}

	// 水压设备样式
	.shuiya-shuiwei {
		width: 100%;
		height: 260px;
		display: flex;
		box-sizing: border-box;

		.shuiya-1 {
			width: 20%;
		}

		.shuiya-2 {
			width: 80%;
			margin-left: 15px;
			height: 240px;

			padding: 10px;

			// border: 1px solid rgb(136, 130, 131);
			.description {
				color: #999;
				position: absolute;
				bottom: 0;
				margin: 10px;
				letter-spacing: 2px;
			}

			.updateTime {
				color: #666;
				position: absolute;
				bottom: 0;
				right: 0;
				font-size: 16px;
				margin: 10px;
			}

			.shuiya-2-1 {
				div {
					margin: 10px;
					font-size: 18px;
					height: 30px;
					line-height: 30px;

					span {
						font-weight: 600;
					}
				}
			}
		}
	}

	// 智慧用电样式
	.smart-ele {
		height: 180px;
		overflow: scroll;
		//   border: 1px solid red;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;

		.smart-ele-1 {
			box-sizing: border-box;
			border-radius: 5px;
			cursor: pointer;
			font-size: 16px;
			width: 20%;
			display: flex;
			// height: 100px;
			padding: 10px;

			.image-box {
				img {
					width: 60px;
				}
			}

			.text-box {
				font-size: 16px;
				height: 55px;
				line-height: 55px;
				padding-left: 10px;

				width: 72%;
				overflow: hidden;
				text-overflow: ellipsis; //显示 ...
				white-space: nowrap; //不换行

				// 实时数据值
				.value {
					color: #7dc5eb;
					font-weight: 600;
				}
			}
		}

		.smart-ele-1:hover {
			transition: 1s;
			background: rgba(0, 0, 0, 0.1);
		}
	}

	.addtasks {
		display: inline-block;
		float: right;
		color: #489ff0;
		cursor: pointer;
	}

	::-webkit-scrollbar {
		width: 5px;
		background: #7dc5eb;
	}
</style>