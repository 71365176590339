<template>
  <div id="app">
    <!-- 设备信息 -->
    <div>
      <DeviceBaseInfo :deviceOfRow="deviceOfRow" />
    </div>
    <!-- 实时数据 -->
    <div>
      <DeviceValue :deviceId="deviceId" :deviceTypeId="deviceTypeId" :deviceOfRow="deviceOfRow"/>
    </div>
    <div>
      <el-collapse v-model="mapName" accordion :disabled="false">
        <el-collapse-item name="1">
          <template #title>
            <div class="map-title">
              <i class="el-icon-menu map-title"></i>
              <span> 地图</span>
            </div>
          </template>
          <div class="map-content">
            <DeviceMap :positions="positions" />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
// 地图
import DeviceMap from '@/components/cloudPlatform/Center/deviceMap'
// 查询设备经纬度
import deviceTypeAboutMap from '@/api/manageApi/DeviceTypeAboutMap'
// 设备基本信息组件
import DeviceBaseInfo from '@/views/manage/aSingleDeviceInformation/DeviceBaseInfo'
// 设备动态属性 组件
import DeviceValue from '@/views/manage/aSingleDeviceInformation/DeviceValue'
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

export default {
  components: {
    DeviceValue, DeviceBaseInfo, DeviceMap
  },
  props: ['deviceOfRow'],
  data() {
    return {
      mapName: '1',
      activeName: '1',
      //   经纬度
      positions: [],
      //   动态属性组件需要这个id查属性值
      deviceId: this.deviceOfRow.id,
      //   动态属性组件需要根据typeId处理数据
      deviceTypeId: this.deviceOfRow.typeId,
      positions: {
        lng: '',
        lat: '',
      },
    }
  },

  created() {
    console.log(this.deviceOfRow);
    this.getDeviceType();
  },

  methods: {
    async getDeviceType() {
      const { data: res } = await deviceTypeAboutMap.getOneDeviceTypeAboutMap(this.deviceId);
      if (res.data.list.length == 0) {
        // setTimeout(() => {
        //   this.$message.warning('该设备尚未添加位置信息！')
        // }, 3000);
        return
      } else {
        this.positions.lng = res.data.list[0].lng;
        this.positions.lat = res.data.list[0].lat;
      }
    },

  },

}
</script>

<style lang="scss" scoped>
/deep/.el-collapse-item__header {
  letter-spacing: 3px;
  background: #eff0f0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
/deep/.el-collapse-item__wrap {
  padding: 10px;
  background: #fff;
}
.title {
  background: #eff0f0;
  height: 50px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 600;
  color: cadetblue;
  padding-left: 10px;
}
.map-title {
  background: #eff0f0;
  height: 50px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 600;
  color: cadetblue;
  padding-left: 5px;
}
</style>