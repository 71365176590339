<template>
  <!-- 水压 -->
  <div class="container">
    <dv-border-box-7 style="height:260px">
      <div ref="innerCircle" class="config"></div>
    </dv-border-box-7>
  </div>
</template>

<script>

import * as echarts from 'echarts';
import 'echarts-liquidfill/dist/echarts-liquidfill.min.js';

export default {
  name: 'WaterPressure',
  props: ["data", "deviceOfRow"],
  data() {
    return {

      // 当前值
      pressureValue: 0,
      arrPressureValue: [],
      // 低阈值
      lowThreshold: 0,
      // 高阈值
      highThreshold: 0,

      max: 0,
      min: 0,

    }

  },

  created() {
    console.log(this.data);
    console.log(this.deviceOfRow);

  },

  mounted() {
    setTimeout(() => {
      this.drawWaterBubble()
    }, 1000)
  },

  methods: {
    getData() {
      console.log(this.data);

      //   高阈值和低阈值
      for (let i = 0; i < this.deviceOfRow.device.length; i++) {
        if (this.deviceOfRow.device[i].proName == "低阈值") {
          this.lowThreshold = Number(this.deviceOfRow.device[i].value)
          console.log('低阈值：' + this.lowThreshold);
        }
        if (this.deviceOfRow.device[i].proName == "高阈值") {
          this.highThreshold = Number(this.deviceOfRow.device[i].value)
          console.log('高阈值:' + this.highThreshold);
        }
      }
      this.min = 0;
      this.max = (this.highThreshold)
      if (this.max == 0) {
        this.max = 10
      }
      console.log(this.max, this.min);


      // 当前水位值
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].name == "值") {
          this.pressureValue = this.data[i].value;
          if (this.pressureValue == "未服务") {
            this.pressureValue = 0
          }
          this.arrPressureValue.push(this.pressureValue / this.max)
          console.log(this.pressureValue);
        }
      }
    },
    drawWaterBubble() {
      this.getData()

      let _this = this;
      const myEcharts = echarts.init(this.$refs.innerCircle)
      let options = {
        // backgroundColor: '#181b22',
        title: [
          {
            text: '水位',
            textStyle: {
              fontSize: '20',
              fontWeight: '600',
              color: '#333',
            },
          },
        ],

        series: [
          {
            min: _this.min,
            max: _this.max,
            type: 'liquidFill',
            outline: {
              show: true,
            },
            backgroundStyle: {
              color: 'rgba(41, 77, 153, 0.8)'
            },
            shape: 'circle',
            radius: '90%',
            itemStyle: {
              color: _this.pressureValue < _this.lowThreshold ? '#FDDD60' : _this.pressureValue > _this.highThreshold ? '#FF6E76' : '#24c8ed',
              opacity: 1
            },
            data: _this.arrPressureValue,
            label: {
              show: true,
              fontSize: '30',
            },
          }
        ]
      }
      myEcharts.setOption(options, true)
    },

  }

}
</script>

<style lang="scss" scoped>
.config {
  width: 100%;
  //   border: 1px solid red;
  height: 240px;
  margin: 10px auto;
  padding: 10px;
  box-sizing: border-box;
}

.content {
  position: relative;

  .status {
    display: inline-block;
    width: 100px;
    position: absolute;
    top: 0;
    left: 0%;
  }
}
</style>