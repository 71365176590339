<template>
  <div id="app">
    <!-- 设备基本信息 -->
    <div>
      <DeviceBaseInfo :deviceOfRow="deviceOfRow" />
    </div>

    <!-- 设备基本信息 -->
    <el-collapse v-model="dataList" accordion :disabled="false">
      <el-collapse-item name="1">
        <template #title>
          <div class="title">
            <i class="el-icon-menu title"></i>
            <span> 报警历史记录</span>
          </div>
        </template>
        <div>
          <el-table :data="alarmHistorylist" style="width: 100%">
            <el-table-column fixed type="index" width="60" align="center">
            </el-table-column>
            <el-table-column prop="typeName" label="设备类型名称" align="center" width="240">
            </el-table-column>
            <!-- <el-table-column prop="deviceId" label="设备号"  align="center">
            </el-table-column> -->
            <el-table-column prop="note" label="安装位置" align="center" width="240">
            </el-table-column>
            <el-table-column prop="notifySystemId" label="所属系统" align="center" width="260">
            </el-table-column>
            <!-- <el-table-column prop="typeId" label="设备类型ID"  align="center">
            </el-table-column> -->

            <!-- <el-table-column prop="value" label="报警类型" align="center">
            </el-table-column> -->

            <el-table-column label="报警类型" align="center" width="300">
              <template slot-scope="scope">
                <span :style="scope.row.value.indexOf('警')!=-1?'color:red;font-weight:600;font-size:16px;letter-spacing: 2px':
                                scope.row.value.indexOf('故障')!=-1?'color:orange;font-weight:600;font-size:16px;letter-spacing: 2px':
                                scope.row.value.indexOf('失联')!=-1?'color:#833471;font-weight:600;font-size:16px;letter-spacing: 2px': 
                                                                    'color:#747d8c;font-weight:600;font-size:16px;letter-spacing: 2px'
                                ">{{scope.row.value}}</span>
              </template>
            </el-table-column>

            <el-table-column label="设备状态" align="center" width="240">
              <template slot-scope="scope">
                <el-tag size="small" effect="dark" :type="scope.row.handleStatus=='未处理'||scope.row.handleStatus=='恢复未处理'?'danger':'success'">{{scope.row.handleStatus}}</el-tag>
              </template>
            </el-table-column>

            <el-table-column prop="createTime" label="报警时间" align="center" width="240">
            </el-table-column>

            <el-table-column prop="processType" label="处理方式" align="center" width="240">
            </el-table-column>
            <el-table-column prop="process" label="处理说明" align="center">
            </el-table-column>

            <el-table-column fixed="right" label="操作" align="center" width="280">
              <template slot-scope="scope">
                <el-button @click="toVideo(scope.row)" icon="el-icon-video-camera" size="small" type="warning">视频</el-button>
                <el-button @click="toProcess(scope.row)" icon="el-icon-magic-stick" size="small" type="success">处理</el-button>
                <el-button @click="toCRT(scope.row)" icon="el-icon-view" size="small" type="primary">CRT</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-pagination align="center" background :current-page.sync="current" :total="total" layout="total, prev, pager, next, jumper" @current-change="pagination(current)">
            </el-pagination>
          </div>
        </div>

        <!-- 处理异常设备弹框 -->
        <el-dialog title="异常设备处理" :visible.sync="processDevice" width="35%">
          <div>
            <el-descriptions :column="2" border>
              <el-descriptions-item label="设备号">{{processList.deviceId}}</el-descriptions-item>
              <el-descriptions-item label="设备类型">{{processList.typeName}}</el-descriptions-item>
              <el-descriptions-item label="设备位置">{{processList.note}}</el-descriptions-item>
              <el-descriptions-item label="设备状态">{{processList.value}}</el-descriptions-item>
              <el-descriptions-item label="报警时间">{{processList.createTime}}</el-descriptions-item>
              <el-descriptions-item label="处理状态">
                <el-tag size="small" :type="processList.handleStatus=='未处理'?'danger':'success'">{{processList.handleStatus}}</el-tag>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div>
            <div style="font-size:16px;margin:10px 0">
              请选择要处理的类型：
            </div>
            <div>
              <el-radio-group v-model="radio">
                <el-radio :label="1" border>真实</el-radio>
                <el-radio :label="2" border>误报</el-radio>
                <el-radio :label="3" border>检修</el-radio>
                <el-radio :label="4" border>测试</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div slot="footer">
            <el-button @click="processDevice = false">取 消</el-button>
            <el-button type="primary" @click="processDeviceOk">确 定</el-button>
          </div>
        </el-dialog>

      </el-collapse-item>
    </el-collapse>

  </div>
</template>

<script>

// 系统名称接口
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

// 设备基本信息组件
import DeviceBaseInfo from '@/views/manage/aSingleDeviceInformation/DeviceBaseInfo'

// 查报警历史记录       处理异常设备
import DeviceHistoryStatus from "@/api/manageApi/DeviceHistoryStatus";

// CRT位置信息
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";


export default {
  name: 'AlarmHistoryData',
  props: ['deviceOfRow'],
  components: {
    DeviceBaseInfo
  },
  data() {
    return {
      // 设备处理弹框
      processDevice: false,

      //   处理类型
      radio: '',

      dataList: '1',
      handleStatus: '', // 处理状态
      current: 1,
      typeName: '',
      valueName: '',
      deviceId: this.deviceOfRow.id,

      limit: 5,
      total: 0,

      //   报警历史数据集合
      alarmHistorylist: [],

      //   处理弹框信息
      processList: [],

      // 设备处理
      deviceHistoryStatus: {},



    }
  },
  created() {
    this.getAlarmHistoryInfo();

  },
  methods: {

    async getAlarmHistoryInfo() {
      let obj = {}
      obj.currentPage = this.current
      obj.handleStatus = this.handleStatus
      obj.typeName = this.typeName
      obj.deviceId = this.deviceId
      obj.valueName = this.valueName
      obj.value = ""
      obj.startTime = ""
      obj.endTime = ""
      // 报警历史记录
      const { data: history } = await DeviceHistoryStatus.getAll(obj)
      this.total = history.data.total
      this.alarmHistorylist = history.data.list;

      // 获取系统名称
      const { data: system } = await noticeSysManagement.getNotifyBigSystemById()

      for (let i = 0; i < this.alarmHistorylist.length; i++) {
        for (let j = 0; j < system.data.list.length; j++) {
          if (this.alarmHistorylist[i].notifySystemId == system.data.list[j].id) {
            this.alarmHistorylist[i].notifySystemId = system.data.list[j].bigSystemName
          }
        }
      }
    },

    // 查看视频
    toVideo(row) {
      console.log(row);
      if (row.videoUrl) {
        window.location.href = row.videoUrl;
      } else {
        this.$message.warning("该设备没有绑定摄像头！")
      }
    },

    // 设备处理
    toProcess(row) {
      this.deviceHistoryStatus = row;
      this.processList = row;
      console.log(row);
      this.processDevice = true;

    },
    // 跳转CRT
    toCRT(row) {
      console.log(row.deviceId);
      crtDeviceCoordinate.getCoordinateByDeviceId(row.deviceId).then((res) => {
        if (res.data.message != "失败") {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });
        } else {

          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });

    },
    // 处理异常设备确认按钮
    processDeviceOk() {
      switch (this.radio) {
        case 1: this.radio = "真实";
          break;
        case 2: this.radio = "误报";
          break;
        case 3: this.radio = "检修";
          break;
        case 4: this.radio = "测试";
          break;
      }
      if (this.radio == '') {
        this.$message.warning("请选择处理类型！")
      } else if (this.deviceHistoryStatus.handleStatus.indexOf('已处理') != -1) {
        this.$message.warning("该设备已处理，请勿重复提交！")
      } else {
        this.deviceHistoryStatus.processType = this.radio
        let obj = {}
        obj.id = this.deviceHistoryStatus.id
        obj.deviceId = this.deviceHistoryStatus.deviceId
        if(this.deviceHistoryStatus.process!=null&&this.deviceHistoryStatus.processType!=""){
          obj.process = this.deviceHistoryStatus.process
        }
        obj.processType = this.deviceHistoryStatus.processType
        DeviceHistoryStatus.updateRecord(obj).then(res=>{
          if(res.data.message=="处理成功"){
            this.$message({
              message: "处理成功！",
              type: "success",
            });
            this.getAlarmHistoryInfo();
          }else{
            this.$message({
              message: "处理失败！",
              type: "error",
            });
            this.getAlarmHistoryInfo();
          }
        })
        this.processDevice = false;
      }
    },
    pagination(current) {
      console.log(this.current);
      this.getAlarmHistoryInfo();
    }
  },

}
</script>

<style lang="scss" scoped>
/deep/.el-collapse-item__header {
  letter-spacing: 3px;
  background: #eff0f0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
/deep/.el-collapse-item__content {
  padding: 0;
}
/deep/.el-collapse-item__wrap {
  padding: 10px;
  background: #fff;
}
.title {
  background: #eff0f0;
  height: 50px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 600;
  color: cadetblue;
  padding-left: 5px;
}

/deep/.el-pagination {
  margin: 20px 0;
  position: static;
}
</style>