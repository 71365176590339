<template>
  <div id="app">
    <el-page-header @back="goBack" :content="deviceOfRow.typeName" class="header"></el-page-header>
    <div>
      <audio id="audio" loop="loop" :src="thisAudioUrl" />
    </div>
    <div v-if="isShowTag" class="container">
      <div>
        <div class="timeShow">{{time}}</div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="实时监控" name="first">
            <div class="content">
              <OverviewInformation :deviceOfRow="deviceOfRow" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="故障预警" name="second">
            <div class="content">
              <AlarmHistoryData :deviceOfRow="deviceOfRow" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="历史数据" name="third">
            <div class="content">
              <HistoricalData :deviceOfRow="deviceOfRow" />
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="isShowCommandHistory" label="操作记录" name="fourth">
            <div class="content" v-if="flag">
              <CommandHistory :deviceOfRow="deviceOfRow" :flag="flag" />
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="参数设置" name="fourth">参数设置</el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>

// 报警历史数据组件
import AlarmHistoryData from '@/views/manage/aSingleDeviceInformation/AlarmHistoryData'
// 历史数据组件
import HistoricalData from '@/views/manage/aSingleDeviceInformation/HistoricalData'
// 实时监控数据组件
import OverviewInformation from '@/views/manage/aSingleDeviceInformation/OverviewInformation'
// 操作指令记录组件
import CommandHistory from '@/views/manage/aSingleDeviceInformation/CommandHistory'
import deviceInfo from "@/api/manageApi/DeviceInfoManagement";
import Command from '@/api/mapBuidingApi/Command'
import BaseData from "@/assets/config/BaseData.js";
import dataStorage from "@/../utils/dataStorage.js";
export default {
  components: {
    OverviewInformation, HistoricalData, AlarmHistoryData, CommandHistory
  },
  data() {
    return {
      flag: false,
      activeName: 'first',
      deviceOfRow: [],
      time: '',
      isShowCommandHistory: false,//是否展示设备操作记录
      deviceId: "",//设备id
      isShowTag: false,//是否展示页面
      thisAudioUrl: BaseData.baseUrl + "crtPicture/fault.mp3",
      path:
        "ws://" +
        BaseData.serverIP +
        ":" +
        BaseData.serverPort +
        "/websocket/" +
        dataStorage.getBaseData("socketUserName"),
    }
  },

  mounted() {
    this.connectWebSocket()//连接webSocket
  },

  created() {
    //   时间显示
    this.time = this.$moment().format("LLLL");
    setInterval(() => {
      this.time = this.$moment().format("LLLL");
    }, 1000);


    this.deviceId = this.$route.query.deviceId;
    this.getDeviceInfo(this.deviceId)
  },
  methods: {
    connectWebSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    open() {
      console.log("查看页面socket连接成功");
    },
    error() {
      console.log("查看页面socket连接失败");
      this.socket = new WebSocket(this.path);
      // 监听socket连接
      this.socket.onopen = this.open;
      // 监听socket错误信息
      this.socket.onerror = this.error;
      // 监听socket消息
      this.socket.onmessage = this.getMessage;
    },
    getMessage(msg) {
      console.log("收到的消息===============");
      console.log(msg);

      var jsonMsg = JSON.parse(msg.data);

      //指令下发反馈提示
      if (jsonMsg.orderMsg != undefined && jsonMsg.orderMsg != "" && jsonMsg.orderMsg != null) {
        if (jsonMsg.orderMsg.indexOf('操作成功') != -1) {
          jsonMsg.status = true
        } else {
          jsonMsg.status = false
        }
        this.commandMessage(jsonMsg);
        // if (jsonMsg.orderMsg.indexOf('操作成功') != -1) {
        //   this.$message.success(jsonMsg.orderMsg)
        // } else {
        //   this.$message.error(jsonMsg.orderMsg)
        // }
      } else if (jsonMsg.type != undefined && jsonMsg.type != "" && jsonMsg.type == 'aKeyAlarm') {
        //一键报警弹窗
        this.aKeyAlarmFrame(jsonMsg);
      } else if (jsonMsg.type == "normalDevice") {
        //接收设备正常数据
      } else {
        // 此方法为弹框
        this.springFrame(jsonMsg);
      }
    },
    //生成指令消息弹框
    commandMessage(jsonMsg) {
      let message = jsonMsg.orderMsg
      // 指令下发信息弹框
      if (jsonMsg.status) {
        this.$notify.success({
          title: "指令下发",
          dangerouslyUseHTMLString: true,
          message: message,
          duration: 0,
          position: "bottom-right",
          onClick: null,
          onClose: null,
        });
      } else {
        this.$notify.error({
          title: "指令下发",
          dangerouslyUseHTMLString: true,
          message: message,
          duration: 0,
          position: "bottom-right",
          onClick: null,
          onClose: null,
        });
      }
    },
    //一键报警弹窗
    aKeyAlarmFrame(msg) {
      console.log(msg);
      this.aKeyAlarmId = msg.id
      this.$notify({
        title: "一键报警",
        dangerouslyUseHTMLString: true,
        customClass: "notify-warning",
        message: `<h4 style='color:#000'>${msg.message}</h4>`,
        duration: 0,
        iconClass: "el-icon-warning",
        position: "bottom-right",
        onClick: this.aKeyAlarmJump,
        onClose: null,
      });
    },
    springFrame(msg) {
      let message =
        "【" +
        msg.typeName +
        "-" +
        msg.deviceId +
        "】发生了----------" +
        msg.value;
      console.log(msg);

      if (msg.videoUrl == "") {
      } else {
        if (msg.value.indexOf("警") != -1) {
          console.log(msg.videoUrl);
          this.showVideo(msg.videoUrl);
        }
      }
      this.getForFrame({
        deviceId: msg.deviceId,
        title: msg.type,
        message: message,
        videoUrl: msg.videoUrl,
        typeObject:
          msg.value.indexOf("警") != -1
            ? {
              className: "notify-warning",
              icon: "el-icon-warning",
              color: "#000",
            }
            : msg.value.indexOf("故障") != -1
              ? {
                className: "notify-fault",
                icon: "el-icon-info",
                color: "#000",
              }
              : {},
      });
    },
    showVideo(videoUrl) {
      if (videoUrl) {
        window.location.href = videoUrl;
      } else {
        this.$message({
          type: "error",
          message: "该设备未绑定任何摄像头！",
        });
      }
    },
    getForFrame(msg) {
      var msgString = "<h4 style='color:" +
        msg.typeObject.color +
        "'>" +
        msg.message +
        "</h4><button onclick='toCRT(\"" +
        msg.deviceId +
        "\")'>CRT</button>" +
        (msg.videoUrl == ""
          ? ""
          : "<button style='margin-left:10px' onclick='showVideo(\"" +
          msg.videoUrl +
          "\")'>查看视频</button>");
      // 报警信息弹框
      this.activeDeviceId = msg.deviceId
      this.$notify({
        title: msg.title,
        dangerouslyUseHTMLString: true,
        customClass: msg.typeObject.className,
        message: msgString,
        duration: 0,
        position: "bottom-right",
        iconClass: msg.typeObject.icon,
        onClick: this.jump,
        onClose: this.closeNotify,
      });

      console.log(this.$notify.length);

      this.playaudio();
    },
    jump() {
      //跳转到状态历史记录(告警受理)页面
      this.$router.replace({
        path: "/AlarmAcceptance",
        query: {
          deviceId: this.activeDeviceId,
          randomId: Math.floor((Math.random() * 10000) + 1)
        }
      }).catch(err => err)
      this.stopAudio();
    },
    closeNotify() {
      this.stopAudio();
    },
    playaudio() {
      var audio = document.getElementById("audio");
      audio.play(); //播放一段音频
      //audio.pause()  //暂停一段音频
    },
    stopAudio() {
      var audio = document.getElementById("audio");
      audio.pause(); //暂停一段音频
    },
    goBack() {
      this.activeName = 'first';
      this.$router.go(-1);
    },
    //获取设备信息
    getDeviceInfo(deviceId) {
      let deviceInfoQuery = {}
      deviceInfoQuery.id = deviceId
      deviceInfo.getDeviceInfoById(deviceId).then(res => {
        if (res.data.data.message = "成功") {
          this.deviceOfRow = res.data.data.deviceInfoVo
          this.isShowTag = true
          this.getDeviceCommand(this.deviceOfRow.typeId);
        }
      })
    },
    // 标签页点击事件
    handleClick() {
      if (this.activeName == 'fourth') {
        this.flag = true
      } else {
        this.flag = false
      }
      console.log(this.activeName);
    },
    //获取该设备类型所有的指令,进行处理显示
    getDeviceCommand(typeId) {
      //获取该设备类型所有的指令
      Command.getDeviceCommand(typeId).then(res => {
        // 操作命令
        let deviceCommands = res.data.data.deviceCommands
        if(deviceCommands.length!=0){
          this.isShowCommandHistory = true
        }
      })
    },
  },
  beforeDestroy() {
    this.socket.close();
  },
  destroyed() {
    this.socket.onclose = function () {
      console.log("查看页面socket关闭连接")
    }
  }
}

</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
}
.container {
  margin-top: 70px;
  .content {
    width: 100%;
    height: 84vh;
    overflow: scroll;
  }
}
.goback {
  cursor: pointer;
}
.el-page-header {
  height: 70px;
  line-height: 70px;
  color: aliceblue;
  padding-left: 20px;
  background: #353535;
}
/deep/.el-page-header__content {
  color: aliceblue;
  font-size: 24px;
  font-weight: 600;
}
// 页头
/deep/.el-page-header__title,
.el-icon-back {
  font-size: 18px;
  font-weight: 600;
  color: aliceblue;
}
// 标签页表头
/deep/.el-tabs__nav-scroll {
  height: 60px;
  line-height: 60px;
  background: #fbfbfb;
}
// 日期显示
.timeShow {
  height: 70px;
  line-height: 70px;
  text-align: right;
  position: absolute;
  padding-right: 30px;
  box-sizing: border-box;
  top: 0;
  right: 0;
  font-size: 26px;
  letter-spacing: 2px;
  font-weight: 600;
  color: aliceblue;
}
/deep/.el-tabs__item {
  font-size: 18px !important;
}
/deep/.el-tabs__nav-scroll {
  padding: 0 20px;
}
/deep/.el-icon-back:before {
  font-weight: 600;
}

/deep/.el-tabs__item.is-active {
  color: #5f9ea0;
  font-weight: 600;
  font-size: 22px !important;
  transition: 0.5s;
}
/deep/.el-tabs__item {
  color: #353535;
  transition: 0.5s;
}
/deep/.el-tabs__active-bar {
  background: none;
}
</style>