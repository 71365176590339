<template>
  <!-- 水压 -->
  <div class="container">
    <dv-border-box-7 style="height:260px">
      <div id="main" class="config"></div>
    </dv-border-box-7>
  </div>
</template>

<script>

import * as echarts from 'echarts';

export default {
  name: 'WaterPressure',
  props: ["data", "deviceOfRow"],
  data() {
    return {
      // 当前值
      pressureValue: null,
      // 低阈值
      lowThreshold: 0,
      // 高阈值
      highThreshold: 0,

      max: 0,
      min: 0,

    }

  },

  created() {
    console.log(this.deviceOfRow);


  },

  mounted() {
    //   this.$nextTick(() => {
    //     this.dashboardData()
    //   })
    setTimeout(() => {
      this.dashboardData()
    }, 1000)

  },

  methods: {
    //   获取水压设备数据
    getWaterPressureValue() {
      // 当前水压值
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].name == "值") {
          this.pressureValue = this.data[i].value;
          console.log('实时值：' + this.pressureValue);
        }
      }

      //   高阈值和低阈值
      for (let i = 0; i < this.deviceOfRow.device.length; i++) {
        if (this.deviceOfRow.device[i].proName == "低阈值") {
          this.lowThreshold = Number(this.deviceOfRow.device[i].value)
          console.log('低阈值：' + this.lowThreshold);
        }
        if (this.deviceOfRow.device[i].proName == "高阈值") {
          this.highThreshold = Number(this.deviceOfRow.device[i].value)
          console.log('高阈值:' + this.highThreshold);
        }
      }

      this.min = 0;
      this.max = (this.highThreshold + 0.5)

      console.log(this.max, this.min);
    },
    //   仪表盘数据
    dashboardData() {

      this.getWaterPressureValue();

      let _this = this;
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);

      let option = {
        title: {
          text: this.deviceOfRow.typeName,
        },
        series: [
          {
            type: 'gauge',
            startAngle: 225,
            endAngle: -45,

            min: _this.min,
            max: _this.max,

            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 8,   // 表盘线宽

                color: [
                  [(_this.lowThreshold / _this.max).toFixed(2), '#FDDD60'],
                  [(_this.highThreshold / _this.max).toFixed(2), '#7CFFB2'],
                  [1, '#FF6E76']
                ]

              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '40%',
              width: 6,
              offsetCenter: [0, '-20%'],
              itemStyle: {
                color: '#008c8c'
              }
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: '#008c8c',
                width: 2
              }
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: '#008c8c',
                width: 5
              }
            },
            axisLabel: {
              color: '#008c8c',
              fontSize: 13,
              distance: -60,
              formatter: function (value) {
                return value.toFixed(2);
              }
            },
            title: {
              offsetCenter: [0, '-30%'],
              fontSize: 16,
              color: _this.pressureValue == '' || _this.pressureValue == null || _this.pressureValue == undefined ? '#666' :
                     _this.pressureValue < _this.lowThreshold ? '#FDDD60' : _this.pressureValue > _this.highThreshold ? '#FF6E76' : '#7CFFB2',
            },
            detail: {
              fontSize: 18,
              offsetCenter: [0, '-0%'],
              valueAnimation: true,
              formatter: function (value) {
                // return _this.pressureValue + 'Mpa';
                return _this.pressureValue == '' || _this.pressureValue == null || _this.pressureValue == undefined ? ''+ 'Mpa':
                       (_this.pressureValue + 'Mpa');
              },
              color: '#008c8c',
            },
            data: [
              {
                value: _this.pressureValue,
                name: _this.pressureValue == '' || _this.pressureValue == null || _this.pressureValue == undefined ? '无数据' :
                  _this.pressureValue < _this.lowThreshold ? '低值' : _this.pressureValue > _this.highThreshold ? '高值' : '正常'
              }
            ]
          }
        ]
      };
      option && myChart.setOption(option, true);
    }
  }


}
</script>

<style lang="scss" scoped>
.config {
  width: 100%;
  //   border: 1px solid red;
  height: 300px;
  padding: 10px;
  box-sizing: border-box;
}
.content {
  position: relative;
  .status {
    display: inline-block;
    width: 100px;
    position: absolute;
    top: 0;
    left: 0%;
  }
}
</style>