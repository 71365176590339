<template>
  <div id="app">
    <!-- 设备基本信息 -->
    <div>
      <DeviceBaseInfo :deviceOfRow="deviceOfRow" />
    </div>

    <!-- 设备基本信息 -->
    <el-collapse v-model="dataList" accordion :disabled="false">
      <el-collapse-item name="1">
        <template #title>
          <div class="title">
            <i class="el-icon-menu title"></i>
            <span> 设备历史记录</span>
          </div>
        </template>
        <div>
          <el-table :data="historyDataList" style="width: 100%">
            <el-table-column type="index" width="60" align="center">
            </el-table-column>
            <el-table-column prop="deviceId" label="设备号" align="center">
            </el-table-column>
            <el-table-column prop="typeName" label="设备类型" align="center">
            </el-table-column>
            <el-table-column prop="valueName" label="设备属性" align="center">
            </el-table-column>
            <el-table-column prop="value" label="属性值" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="记录时间" align="center">
            </el-table-column>
          </el-table>
          <div>
            <el-pagination align="center" background :current-page.sync="current" :total="total" layout="total, prev, pager, next, jumper" @current-change="pagination(current)">
            </el-pagination>
          </div>
        </div>

      </el-collapse-item>
    </el-collapse>

  </div>
</template>

<script>

// 设备基本信息组件
import DeviceBaseInfo from '@/views/manage/aSingleDeviceInformation/DeviceBaseInfo'

// 分页查当前设备历史记录
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";

export default {
  name: 'HistoricalData',
  props: ["deviceOfRow"],
  components: {
    DeviceBaseInfo,
  },
  data() {
    return {
      currentPage: 1, //第几页
      typeName: "", //设备类型
      valueName: "", //设备属性


      historyDataList: [],
      dataList: '1',
      // 页码
      limit: 5,
      current: 1,
      total: 0,


      // deviceHistoryStatus: {
      //         deviceId: "",
      //         typeName: "",
      //         valueName: "",
      //         createTime: "",
      //         handleStatus: "",
      //         process: "",
      //         processType: "",
      //       },
      handleStatus: '', // 处理状态
      
      deviceId: "", //设备号



    }
  },
  created() {
    this.getHistoryData()

  },
  methods: {
    async getHistoryData() {
      // 历史记录
      const { data: res } = await DeviceHistoryValue.getAll(
        this.current,
        this.typeName,
        this.deviceOfRow.id,
        this.valueName
      )
      console.log(res);
      console.log(this.deviceOfRow);
      console.log(this.historyDataList);
      this.historyDataList = res.data.list;
      this.total = res.data.total;

    },


    pagination() {
      console.log(this.current);
      this.getHistoryData()
    },

  }

}
</script>

<style lang="scss" scoped>
/deep/.el-collapse-item__header {
  letter-spacing: 3px;
  background: #eff0f0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
/deep/.el-collapse-item__content {
  padding: 0;
}
/deep/.el-collapse-item__wrap {
  padding: 10px;
  background: #fff;
}
.title {
  background: #eff0f0;
  height: 50px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 600;
  color: cadetblue;
  padding-left: 5px;
}

/deep/.el-pagination {
  margin: 20px 0;
  position: static;
}
</style>